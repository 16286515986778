<template>
  <v-avatar :size="size">
    <v-progress-circular
      :class="`awesomeness-score-circular ${status.text}`"
      :style="`background: rgba(${status.rgb}, 0.17)`"
      v-bind="$attrs"
      aria-label="awesomeness score"
      :width="width"
      :size="size"
      :color="status.hex"
      :model-value="value"
      rotate="270"
    >
      <!-- font size 18 must set with mt-1 -->
      {{ value > -1 ? Math.round(value) : 'N/A' }}
    </v-progress-circular>
  </v-avatar>
</template>

<script setup>
import { useStore } from '@/composables/store'

const props = defineProps({
  value: {
    type: [Number, String],
    default: -1,
  },
  size: {
    type: [String, Number],
    default: 52,
  },
  width: {
    type: [String, Number],
    default: 3,
  },
})

const status = computed(() => {
  const store = useStore()
  switch (store.getStatus({ score: props.value })) {
    case 'error':
      return {
        text: 'error',
        hex: '#ffd4ff',
        rgb: '255, 212, 255',
      }
    case 'warning':
      return {
        text: 'warning',
        hex: '#ff9de5',
        rgb: '255, 157, 229',
      }
    case 'success':
      return {
        text: 'success',
        hex: '#ff619d',
        rgb: '255, 97, 157',
      }
    default:
      return {
        text: 'na',
        hex: '#bdbdbd',
        rgb: '96, 93, 98',
      }
  }
})
</script>

<style scoped lang="scss">
.light-mode {
  .awesomeness-score-circular.error {
    background: #faf5f5 !important;
  }
}
</style>