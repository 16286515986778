<template>
  <v-avatar
    :color="noBorder ? 'transparent' : 'rgba(255,255,255, 0.97)'"
    class="elevation-1"
    :size="size"
  >
    <v-avatar tile :size="size">
      <v-avatar tile :size="faviconSize">
        <NuxtImg
          loading="lazy"
          :width="faviconSize"
          :height="faviconSize"
          :src="`https://www.google.com/s2/favicons?domain=${domain}`"
          :alt="`favicon of ${domain}`"
        >
          <template #placeholder>
            <app-img-placeholder />
          </template>
        </NuxtImg>
      </v-avatar>
    </v-avatar>
  </v-avatar>
</template>

<script setup>
const props = defineProps({
  domain: {
    type: String,
    default: '',
  },
  size: {
    type: [String, Number],
    default: 24,
  },
  noBorder: {
    type: Boolean,
    default: false,
  },
})

const faviconSize = props.noBorder ? props.size : props.size - 8
</script>
